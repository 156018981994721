@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Rubik:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Rubik', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.sidebar-item-act::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 10px;
  background-color: #2e7462;
  width: 100%;
  left: 0;
  bottom: -2px;
  border-radius: 10px;
}

.activeMenu::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 10px;
  background-color: #ffbf1a;
  width: 80%;
  left: 5px;
  right: 5px;
  bottom: -10px;
  border-radius: 10px;
}
.activeMenuService::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 10px;
  background-color: #ffbf1a;
  width: 80%;
  left: 0px;
  right: 5px;
  bottom: -10px;
  border-radius: 10px;
}
.bg-search-form {
  background: linear-gradient(90deg, #2fab73 3.52%, #2e7462 79%);
  border-bottom: 1px solid #d1d1d1;
}
.bg-announcement {
  background: linear-gradient(
    270deg,
    rgba(121, 237, 196, 0.3) 1.66%,
    #f3f3f3 35.26%,
    #f3f3f3 100%
  );
  /* box-shadow: -1px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 15px;
}

.bg-hero-text {
  background: linear-gradient(270deg, #124d3e -1.91%, #23bb86 100%);
}
.bg-last-article {
  background: radial-gradient(64% 100% at 50% 100%, #109774 0%, #124d3e 100%);
}

.bg-subs {
  background: linear-gradient(89.77deg, #f6f7f9 46.79%, #d0fce8 103.17%);
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.bg-second-header {
  background: linear-gradient(90deg, #2fab73 3.52%, #2e7462 79%);
  border-bottom: 1px solid #d1d1d1;
}

.side-category-act .ctg-text::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 10px;
  background-color: #2fab73;
  width: 100%;
  left: 0;
  bottom: -2px;
  border-radius: 10px;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  background-color: transparent;
}

.swiper-pagination .swiper-pagination-bullet {
  border-radius: 10px;
  background-color: #fff !important;
  color: #fff;
  opacity: 1 !important;
  transition: all ease-in-out 0.5s;
  height: 10px;
  width: 10px;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 20px;
  height: 10px;
  border-radius: 10px;
  background-color: #ffbf1a !important;
}

.swiper-button-prev {
  background-color: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}
.swiper-button-next {
  background-color: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}
.swiper .swiper-button-prev::after {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px !important;
  color: #888;
}
.swiper .swiper-button-next::after {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px !important;
  color: #888;
}

@layer base {
  .text-primary {
    @apply text-[#2E7462];
  }
  .border-primary {
    @apply border-[#2E7462];
  }
  .bg-primary {
    @apply bg-[#2E7462] text-white;
  }
  .text-primary-lighten {
    @apply text-[#2FAB73];
  }
  .text-secondary {
    @apply text-[#4F5665];
  }
  .border-primary-lighten {
    @apply border-[#2FAB73];
  }
  .bg-primary-lighten {
    @apply bg-[#2FAB73] text-white;
  }
  .text-warning {
    @apply text-[#FFBF1A];
  }
  .border-warning {
    @apply border-[#FFBF1A];
  }
  .bg-warning {
    @apply bg-[#FFBF1A] text-[#282828];
  }
  .absolute-full {
    @apply absolute left-0 right-0 top-0 bottom-0;
  }
  p {
    @apply leading-[30px];
  }

  article p {
    @apply my-4 text-gray-500;
  }
  article h1 {
    @apply my-4 text-gray-500 text-4xl;
  }
  article h2 {
    @apply my-4 text-gray-500 text-3xl;
  }
  article h3 {
    @apply my-4 text-gray-500 text-2xl;
  }
  article h4 {
    @apply my-4 text-gray-500 text-xl;
  }
  article h5 {
    @apply my-4 text-gray-500 text-lg;
  }
  article h6 {
    @apply my-4 text-gray-500 text-base;
  }
}
